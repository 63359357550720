@import '/styles/mixins.scss';
@import '/styles/variables.scss';

html,
body {
  @include font($weight: 400, $family: $inter);
  @include container(0, 0, $lightgrey);
  margin: 0;
  font-size: 16px;

  @include mobile {
    background-color: $blue;
    overflow-x: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  input,
  textarea {
    @include container(0.75rem, $radius, $lightgrey);
    @include font($weight: 400, $family: $inter);
    width: 100%;
    border: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button {
      display: none;
      visibility: hidden;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  textarea {
    resize: vertical;
  }

  ::placeholder {
    color: $grey;
  }

  button {
    @include font($weight: 400, $family: $inter);
    border: none;
    cursor: pointer;
  }
}

* {
  box-sizing: border-box;
}